import { ReactNode } from 'react'

interface Props {
  targetWidth: number
  children: ReactNode
  className?: string
}

export const BASE_FONT_SIZE = 8

const SimpleFluidContainer = ({ targetWidth, children, ...props }: Props) => {
  return (
    <div
      style={{
        '--baseFontSize': BASE_FONT_SIZE,
        fontSize: `calc(calc((min(${targetWidth}px, 100vw) / ${targetWidth}) * var(--baseFontSize))`
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export default SimpleFluidContainer
