import { ButtonLink } from '@/components/Button'
import CloudinaryImage from '@/components/CloudinaryImage'
import SimpleFluidContainer from '@/components/FluidContainer/SimpleFluidContainer'
import { CLOUDINARY_DEFAULT_IMAGE_QUALITY } from '@/constants/cloudinary'
import useBreakpoints from '@/hooks/useBreakpoints'

const WalletWelcomeBlade = () => {
  const imgPath = 'pages/home/'
  const bgImg = 'walletBladeBg'
  const bgImgMobile = 'walletBladeBgMobile'
  const img = 'welcome-stamps'

  const { isLarge } = useBreakpoints()

  return (
    <div className="mx-auto relative w-full">
      <CloudinaryImage
        path={imgPath}
        imageId={isLarge ? bgImg : bgImgMobile}
        layout="fill"
        objectFit="cover"
        alt="Bg"
        className="-z-10 w-full"
        transformations={{
          quality: CLOUDINARY_DEFAULT_IMAGE_QUALITY
        }}
      />
      <SimpleFluidContainer
        targetWidth={1248}
        className="container lg:aspect-[1440/638] flex flex-col lg:flex-row flex-1 items-center gap-[40px] lg:gap-13 py-8 lg:py-0"
      >
        <div className="flex flex-col flex-1 gap-[18px] lg:gap-[2.25em] items-center lg:items-start">
          <div className="utility-lg">DROPPP Marketplace</div>
          <div className="h2 text-center lg:text-left lg:text-[7em]">
            Welcome AK, HI, MN, & NY!
          </div>
          <p className="text-center lg:text-left mb-[18px] body lg:text-[2.25em]">
            Droppp Marketplace is now supported in all 50 U.S. States, including
            Alaska, Hawaii, Minnesota, and New York. Verify your identity, setup
            your wallet, and start buying and listing items today!
          </p>
          <div className="flex flex-col lg:flex-row gap-2">
            <ButtonLink href="/marketplace" theme="blue" size="lg">
              Start Shopping
            </ButtonLink>
            <ButtonLink href="/wallet" theme="white" size="lg">
              Verify Identity
            </ButtonLink>
          </div>
        </div>
        <div className="flex flex-col flex-1 aspect-[338/205] max-w-[338px] min-h-[205px] lg:aspect-[588/360] lg:max-w-[588px] items-center relative">
          <CloudinaryImage
            path={imgPath}
            imageId={img}
            layout="fill"
            objectFit="cover"
            alt="Stamps"
            className="-z-10"
            transformations={{
              quality: CLOUDINARY_DEFAULT_IMAGE_QUALITY
            }}
          />
        </div>
      </SimpleFluidContainer>
    </div>
  )
}

export default WalletWelcomeBlade
