import { ButtonLink } from '@/components/Button'
import CloudinaryImage from '@/components/CloudinaryImage'
import SimpleFluidContainer from '@/components/FluidContainer/SimpleFluidContainer'
import GradientText from '@/components/GradientText'
import PoweredBySolana from '@/components/PoweredBySolana'
import { CLOUDINARY_DEFAULT_IMAGE_QUALITY } from '@/constants/cloudinary'
import useBreakpoints from '@/hooks/useBreakpoints'

const WalletMigrationBlade = ({}) => {
  const imgPath = 'pages/home/'
  const bgImg = 'walletBladeBg'
  const bgImgMobile = 'walletBladeBgMobile'
  const img = 'wallet-card'

  const { isLarge } = useBreakpoints()

  return (
    <div className="mx-auto relative w-full">
      <CloudinaryImage
        path={imgPath}
        imageId={isLarge ? bgImg : bgImgMobile}
        layout="fill"
        objectFit="cover"
        alt="Bg"
        className="-z-10 w-full"
        transformations={{
          quality: CLOUDINARY_DEFAULT_IMAGE_QUALITY
        }}
      />
      <SimpleFluidContainer
        targetWidth={1248}
        className="container lg:aspect-[1440/638] flex flex-col lg:flex-row flex-1 items-center gap-5 lg:gap-[13em] py-8 lg:py-0"
      >
        <div className="flex flex-col flex-1 gap-[18px] lg:gap-[2.25em] items-center lg:items-start">
          <div className="text-center lg:text-left">
            <span className="h2 inline lg:text-[7em]">Migrate to the new </span>
            <GradientText
              theme="solana"
              className="h1 inline lg:text-[7em]"
              text="Droppp Wallet"
            />
            <span className="h1 inline lg:text-[7em]"> now</span>
          </div>
          <PoweredBySolana className="hidden lg:flex gap-[3px] items-center text-gray-300 body-md" />
          <p className="text-center lg:text-left body lg:text-[2.25em] mb-[18px]">
            Follow the migration guide to easily transition to the new Droppp
            Wallet, now supported in all US States.
          </p>
          <ButtonLink theme="white" href="/wallet/migrate" size="lg">
            Migrate Wallet
          </ButtonLink>
        </div>
        <div className="flex flex-col flex-1 min-h-[160px] aspect-[460/291] max-w-[460px] items-center relative">
          <CloudinaryImage
            path={imgPath}
            imageId={img}
            layout="fill"
            objectFit="cover"
            alt="Stamps"
            className="-z-10"
            transformations={{
              quality: CLOUDINARY_DEFAULT_IMAGE_QUALITY
            }}
          />
        </div>
        <PoweredBySolana className="flex lg:hidden gap-[3px] items-center text-gray-300 body-md -mt-3" />
      </SimpleFluidContainer>
    </div>
  )
}

export default WalletMigrationBlade
